import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import RestApi from '../api/RestApi';
// Async Thunk for fetching Kyc
export const fetchUsersFunds = createAsyncThunk(
  'kyc/fetchUsersFunds',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Assuming user state has a 'token' field
      const response = await RestApi.get('/admin/list-funds', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for updating a Kyc
export const updateUserFunds = createAsyncThunk(
  'kyc/updateUserFunds',
  async ({ id, status }, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }; // Assuming user state has a 'token' field
      const { message } = await RestApi.patch(
        `/admin/updateFundStatus/${id}`,
        { status: status },
        config,
      );
      return message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

const usersFundsSlice = createSlice({
  name: 'usersFunds',
  initialState: {
    fundslist: null,
    status: 'idle',
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: state => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      // Fetch KYC
      .addCase(fetchUsersFunds.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchUsersFunds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fundslist = action.payload;
      })
      .addCase(fetchUsersFunds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update paln
      .addCase(updateUserFunds.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateUserFunds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.success = action.payload;
      })
      .addCase(updateUserFunds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = usersFundsSlice.actions;

export default usersFundsSlice.reducer;
