import {
  Home,
  PeopleOutlined,
  ViewCarousel,
  FactCheck,
  Groups,
} from '@mui/icons-material';
import React, { lazy } from 'react';

const DailyQuotes = lazy(() => import('../pages/quotes/DailyQuotes'));
const Settings = lazy(() => import('../pages/vendor/Settings'));
const OnlineTxns = lazy(() => import('../pages/OnlineTxns'));
const RechargeSettings = lazy(() => import('../pages/user/RechargeSettings'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const User = lazy(() => import('../pages/user'));
const Vendor = lazy(() => import('../pages/vendor/Vendor'));
const VendorTransactions = lazy(() =>
  import('../pages/vendor/VendorTransactions'),
);
const Payouts = lazy(() => import('../pages/mlm/Payouts'));
const Banners = lazy(() => import('../pages/Banners'));
const Meeting = lazy(() => import('../pages/Meeting'));
const Plan = lazy(() => import('../pages/mlm/Plan'));
const Kyc = lazy(() => import('../pages/mlm/Kyc'));
const VendorKyc = lazy(() => import('../pages/vendor/VendorKyc'));
const UsersPlans = lazy(() => import('../pages/mlm/UsersPlans'));
const UserFunds = lazy(() => import('../pages/mlm/UserFunds'));
const Gift = lazy(() => import('../pages/gifts/Gift'));

export const appRoutes = [
  {
    group: 'Dashboard',
    title: 'Dashboard',
    path: '/',
    element: <Dashboard />,
    isChild: false,
    icon: <Home />,
  },
  {
    group: 'Dashboard',
    title: 'Total Users',
    path: 'users',
    element: <User />,
    isChild: false,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Dashboard',
    title: 'Total  Vendors',
    path: 'vendors',
    element: <Vendor />,
    isChild: false,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Dashboard',
    title: 'All Income History',
    path: 'payouts',
    element: <Payouts />,
    isChild: false,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Vendors',
    title: 'Vendor Transactions',
    path: 'vendortxn',
    element: <VendorTransactions />,
    isChild: false,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Users',
    title: 'Plans',
    path: 'plans',
    element: <Plan />,
    isChild: false,
    icon: <FactCheck />,
  },
  {
    group: 'Users',
    title: 'User KYC',
    path: 'userkyc',
    element: <Kyc />,
    isChild: false,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Users',
    title: 'All Online Transactions',
    path: 'onlinetxn',
    element: <OnlineTxns />,
    isChild: false,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Vendors',
    title: 'Vendor KYC',
    path: 'vendorkyc',
    element: <VendorKyc />,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Users',
    title: 'Users Plans',
    path: 'usersPlans',
    element: <UsersPlans />,
    icon: <FactCheck />,
  },
  {
    group: 'Users',
    title: 'Fund Request',
    path: 'userFunds',
    element: <UserFunds />,
    icon: <FactCheck />,
  },
  {
    group: 'Settings',
    title: 'Banners',
    path: 'banner',
    element: <Banners />,
    icon: <ViewCarousel />,
  },
  {
    group: 'Settings',
    title: 'Daily Quotes',
    path: 'dailyQuotes',
    element: <DailyQuotes />,
    icon: <FactCheck />,
  },

  {
    group: 'Settings',
    title: 'Meeting',
    path: 'meeting',
    element: <Meeting />,
    icon: <Groups />,
  },
  {
    group: 'Settings',
    title: 'Gifts',
    path: 'gifts',
    element: <Gift />,
    icon: <Groups />,
  },
  {
    group: 'Settings',
    title: 'Recharge Settings',
    path: 'recharge',
    element: <RechargeSettings />,
    icon: <PeopleOutlined />,
  },
  {
    group: 'Settings',
    title: 'App Settings',
    path: 'settings',
    element: <Settings />,
    icon: <PeopleOutlined />,
  },
];
