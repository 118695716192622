import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RestApi from '../api/RestApi';
export const userToMaster = createAsyncThunk(
  'user/userToMaster',
  async ({ id, isMaster }, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.post(
        `/auth/user/userToMaster/${id}`,
        { isMaster },
        config,
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);
export const fetchAllUsers = createAsyncThunk(
  'user/fetchAllUsers',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.get(`/admin/listusers`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const userPayouts = createAsyncThunk(
  'user/payouts',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.get('/admin/payouts', config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const onlineTransactions = createAsyncThunk(
  'user/onlineTransactions',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.get('/admin/listAlluserTxn', config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const createSetting = createAsyncThunk(
  'user/createSetting',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.post(
        '/admin/createRechargeSettings',
        formData,
        config,
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const getRechargeSettings = createAsyncThunk(
  'user/getRechargeSettings',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.get(`/admin/getRechargeSettings`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const updateRechargeSetting = createAsyncThunk(
  'user/updateRechargeSetting',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.patch(
        `/admin/updateRechargeSettings/1`,
        formData,
        config,
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    usersList: null,
    payouts: null,
    onlinetxn: null,
    settings: null,
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: state => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllUsers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.usersList = payload;
      })
      .addCase(fetchAllUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(userPayouts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userPayouts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payouts = payload;
      })
      .addCase(userPayouts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(onlineTransactions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(onlineTransactions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.onlinetxn = payload;
      })
      .addCase(onlineTransactions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createSetting.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload;
      })
      .addCase(createSetting.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getRechargeSettings.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRechargeSettings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.settings = payload;
      })
      .addCase(getRechargeSettings.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateRechargeSetting.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRechargeSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload;
      })
      .addCase(updateRechargeSetting.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearMessages } = usersSlice.actions;

export default usersSlice.reducer;
