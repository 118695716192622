import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";
// Async Thunk for fetching Gifts
export const fetchGifts = createAsyncThunk(
  "gift/fetchGifts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Assuming user state has a 'token' field
      const response = await RestApi.get("/admin/getAllGift", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for creating a product
export const createGift = createAsyncThunk(
  "gift/createGift",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      console.log(formData);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.post(
        "/admin/sendGiftToUser",
        formData,
        config
      );
      const data = await response?.data;
      console.log(data);
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for deleting a Banners
export const deleteGift = createAsyncThunk(
  "gift/deleteGift",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { message } = await RestApi.delete(
        `/admin/deleteGifts/${id}`,
        config
      );
      // const data = await response.json();
      return { id, message };
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const giftSlice = createSlice({
  name: "gift",
  initialState: {
    list: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Banners
      .addCase(fetchGifts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGifts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchGifts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create gift
      .addCase(createGift.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGift.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(createGift.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete Banners
      .addCase(deleteGift.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteGift.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, message } = action.payload;
        state.list = state.list.filter((banner) => banner.id !== id);
        state.success = message;
      })
      .addCase(deleteGift.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = giftSlice.actions;

export default giftSlice.reducer;
