import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";
// Async Thunk for fetching Banners
export const fetchBanners = createAsyncThunk(
  "banners/fetchBanners",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Assuming user state has a 'token' field
      const response = await RestApi.get("/admin/list-banners", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for creating a Banners
export const createBanners = createAsyncThunk(
  "banners/createBanners",
  async (banner, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.post(
        "/admin/create-banner",
        banner,
        config
      );
      console.log(response);
      const data = await response?.data;
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);



// Async Thunk for deleting a Banners
export const deleteBanners = createAsyncThunk(
  "banners/deleteBanners",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const {message} = await RestApi.delete(
        `/admin/delete-banner/${id}`,
        config
      );
      // const data = await response.json();
      return { id, message };
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const bannerSlice = createSlice({
  name: "banners",
  initialState: {
    list: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Banners
      .addCase(fetchBanners.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create Banners
      .addCase(createBanners.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBanners.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(createBanners.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })


      // Delete Banners
      .addCase(deleteBanners.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBanners.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id,message } = action.payload;
        state.list = state.list.filter((banner) => banner.id !== id);
        state.success =message;
      })
      .addCase(deleteBanners.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = bannerSlice.actions;

export default bannerSlice.reducer;
