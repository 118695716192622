import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";

// Async thunk for fetching all vendors
export const fetchAllVendors = createAsyncThunk(
  "vendor/fetchAllVendors ",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.get(`/admin/listVendors`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for fetching vendor kyc details

export const fetchVendorKyc = createAsyncThunk(
  "vendor/fetchVendorKyc ",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.get(`/admin/vendorKycDetails`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for fetching vendor transaction details
export const fetchVendorTxn = createAsyncThunk(
  "vendor/fetchVendorTxn ",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.get(`/admin/listAllVendorTxn`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for fetching vendor transaction details
export const getVendorSettings = createAsyncThunk(
  "vendor/getVendorSettings ",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.get(`/admin/getVendorSettings`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for updating a vendor profile
export const updateVendorProfile = createAsyncThunk(
  "kyc/updateVendorProfile",
  async ({ id, isVerified }, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }; // Assuming user state has a 'token' field
      const { message } = await RestApi.patch(
        `/admin/updateVendorProfile/${id}`,
        { isVerified: isVerified },
        config
      );
      return message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createSetting = createAsyncThunk(
  "vendor/createSetting",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.post(
        "/admin/createVendorSettings",
        formData,
        config
      );
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateSetting = createAsyncThunk(
  "vendor/updateSetting",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      console.log(formData);
      const { data } = await RestApi.patch(
        `/admin/updateVendorSettings/1`,
        formData,
        config
      );
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    list: null,
    kyc: null,
    transaction: null,
    settings: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all vendors
      .addCase(fetchAllVendors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllVendors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchAllVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch all vendor kyc details
      .addCase(fetchVendorKyc.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVendorKyc.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.kyc = action.payload;
      })
      .addCase(fetchVendorKyc.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch all vendor transactions
      .addCase(fetchVendorTxn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVendorTxn.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transaction = action.payload;
      })
      .addCase(fetchVendorTxn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch all vendor settings
      .addCase(getVendorSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVendorSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.settings = action.payload;
      })
      .addCase(getVendorSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //   Update Vendor Profile
      .addCase(updateVendorProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateVendorProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payouts = payload;
      })
      .addCase(updateVendorProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payouts = payload;
      })
      .addCase(createSetting.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // to update settings
      .addCase(updateSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payouts = payload;
      })
      .addCase(updateSetting.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearMessages } = vendorSlice.actions;

export default vendorSlice.reducer;
