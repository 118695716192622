import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";
// Async Thunk for fetching Banners
export const fetchQuotes = createAsyncThunk(
  "quote/fetchQuotes",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Assuming user state has a 'token' field
      const response = await RestApi.get("/admin/listAllQuotes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for creating a product
export const createQuote = createAsyncThunk(
  "quote/createQuote",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      console.log(formData);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.post(
        "/admin/createNewQuote",
        formData,
        config
      );
      const data = await response?.data;
      console.log(data);
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for deleting a Banners
export const deleteQuotes = createAsyncThunk(
  "quote/deleteQuotes",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { message } = await RestApi.delete(
        `/admin/deleteQuote/${id}`,
        config
      );
      // const data = await response.json();
      return { id, message };
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const quoteSlice = createSlice({
  name: "quote",
  initialState: {
    list: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Banners
      .addCase(fetchQuotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchQuotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create Banners
      .addCase(createQuote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createQuote.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(createQuote.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete Banners
      .addCase(deleteQuotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteQuotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, message } = action.payload;
        state.list = state.list.filter((banner) => banner.id !== id);
        state.success = message;
      })
      .addCase(deleteQuotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = quoteSlice.actions;

export default quoteSlice.reducer;
