import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import planReducer from "./planSlice";
import categoryReducer from "./categorySlice";
import productReducer from "./productSlice";
import usersReducer from "./usersSlice";
import bannerReducer from "./bannerSlice";
import kycReducer from "./kycSlice";
import usersPlansReducer from "./usersPlanSlice";
import usersFundsReducer from "./userFundsSlice";
import meetingReducer from "./meetingSlice";
import vendorReducer from "./vendorSlice";
import quoteReducer from "./quoteSlice";
import giftReducer from "./giftSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    plan: planReducer,
    categories: categoryReducer,
    products: productReducer,
    users: usersReducer,
    banners: bannerReducer,
    kyc: kycReducer,
    usersPlans: usersPlansReducer,
    usersFunds: usersFundsReducer,
    meeting: meetingReducer,
    vendor: vendorReducer,
    quote: quoteReducer,
    gift: giftReducer,
  },
});
export default store;
