import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestApi from "../api/RestApi";

// Async Thunk for listing all plans
export const listAllPlans = createAsyncThunk(
  "plans/listAll",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { data } = await RestApi.get("/admin/listplan");
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for fetching a plan by its ID
export const fetchPlanById = createAsyncThunk(
  "plans/fetchById",
  async (planId, { getState, rejectWithValue }) => {
    try {
      const response = await RestApi.get(`/admin/listplan/${planId}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for creating a category
export const createPlan = createAsyncThunk(
  "plans/createPlan",
  async (plan, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.post("/admin/createplan", plan, config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Update Plan
export const updatePlan = createAsyncThunk(
  "plans/updatePlan",
  async ({ planId, formData }, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      
      const { data } = await RestApi.patch(
        `/admin/updateplan/${planId}`,
        formData,
        config
      );
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for deleting a plan
export const deletePlan = createAsyncThunk(
  "plans/deletePlan",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.delete(`/admin/deleteplan/${id}`, config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const planSlice = createSlice({
  name: "plans",
  initialState: {
    planList: null,
    selectedPlan: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearSelectedPlan: (state) => {
      state.selectedPlan = null;
    },
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // List all plans
      .addCase(listAllPlans.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listAllPlans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.planList = action.payload;
      })
      .addCase(listAllPlans.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch plan by ID
      .addCase(fetchPlanById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlanById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedPlan = action.payload;
      })
      .addCase(fetchPlanById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Create plan
      .addCase(createPlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update plans
      .addCase(updatePlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(updatePlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete plan
      .addCase(deletePlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(deletePlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearSelectedPlan, clearMessages } = planSlice.actions;

export default planSlice.reducer;
