import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";

// Async Thunk for fetching product
export const fetchMeetings = createAsyncThunk(
  "product/fetchMeetings",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Assuming user state has a 'token' field
      const response = await RestApi.get("/admin/getLiveSessions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for creating a product
export const createMeeting = createAsyncThunk(
  "meeting/createMeeting",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      console.log(formData);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.post(
        "/admin/createNewLiveSession",
        formData,
        config
      );
      const data = await response?.data;
      console.log(data);
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for updating a product
export const updateMeeting = createAsyncThunk(
  "meeting/updateMeeting",
  async ({ id, product }, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }; // Assuming user state has a 'token' field
      const response = await RestApi.patch(
        `/admin/updateLiveSession/${id}`,
        product,
        config
      );
      const data = await response.data;
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for deleting a product
export const deleteMeeting = createAsyncThunk(
  "meeting/deleteMeeting",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.delete(
        `/admin/deleteLiveSession/${id}`,
        config
      );
      const data = await response.json();
      //   return { id, data };
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const meetingSlice = createSlice({
  name: "meeting",
  initialState: {
    list: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch product
      .addCase(fetchMeetings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMeetings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchMeetings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create meeting
      .addCase(createMeeting.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createMeeting.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(createMeeting.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update meeting
      .addCase(updateMeeting.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateMeeting.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.list.findIndex(
          (meeting) => meeting.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
          state.success = "meeting updated successfully";
        }
      })
      .addCase(updateMeeting.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete product
      .addCase(deleteMeeting.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteMeeting.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id } = action.payload;
        state.list = state.list.filter((meeting) => meeting.id !== id);
        state.success = "meeting deleted successfully";
      })
      .addCase(deleteMeeting.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = meetingSlice.actions;

export default meetingSlice.reducer;
