import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";
// Async Thunk for fetching Kyc
export const fetchKyc = createAsyncThunk(
  "kyc/fetchKyc",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Assuming user state has a 'token' field
      const response = await RestApi.get("/admin/listusersKyc", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for updating a Kyc
export const updateKyc = createAsyncThunk(
  "kyc/updateKyc",
  async (kyc, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }; // Assuming user state has a 'token' field
      const { message } = await RestApi.patch(
        `/admin/updateUsersKyc/${kyc.id}`,
        { isKycVerified: kyc.isKycVerified },
        config
      );

      return message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for updating a Kyc
export const updateVendorKyc = createAsyncThunk(
  "kyc/updateVendorKyc",
  async (kyc, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }; // Assuming user state has a 'token' field
      const { message } = await RestApi.patch(
        `/admin/updateVendorKycDetails/${kyc.id}`,
        { isKycVerified: kyc.isKycVerified },
        config
      );

      return message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    list: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch KYC
      .addCase(fetchKyc.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchKyc.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchKyc.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Update kyc
      .addCase(updateKyc.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateKyc.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(updateKyc.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = kycSlice.actions;

export default kycSlice.reducer;
